<template>
  <b-card>
    <b-form @submit.prevent>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="ФИО:"
            label-for="fio"
          >
            <b-form-input
              id="fio"
              v-model="usersData.full_name"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Дата рождения:"
            label-for="dob"
          >
            <flat-pickr
              id="dob"
              v-model="usersData.dob"
              class="form-control"
              :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
            />

          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Серийный номер (Паспорта):"
            label-for="serial_number"
          >
            <cleave
              id="serial_number"
              v-model="usersData.serial_number"
              class="form-control"
              :raw="true"
              :options="{delimiters: ['   '], blocks: [4, 6], uppercase: true}"
              placeholder="0000 000000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Место регистрации:"
            label-for="registration_place"
          >
            <b-form-input
              id="registration_place"
              v-model="usersData.registration_place"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="E-mail:"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="usersData.email"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Пароль:"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="usersData.password"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        variant="primary"
        @click="formSubmit"
      >
        {{ usersData.id ? 'Обновить' : 'Создать' }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import {
  BCard, BRow, BCol, BFormInput, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'

export default {
  name: 'UsersForm',
  components: {
    BButton,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    flatPickr,
    Cleave,
  },
  props: ['usersData'],
  methods: {
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
