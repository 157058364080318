<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <div v-else>
      <Form
        :users-data.sync="formData"
        @formSubmit="doFormSave"
      /></div>
  </b-card>
</template>

<script>
// import axiosIns from '@/libs/axios'
import { BCard, BSpinner } from 'bootstrap-vue'
import store from '@/store'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  full_name: '',
  dob: '',
  password: '',
  serial_number: null,
  registration_place: '',
  email: '',
})

export default {
  name: 'UsersCreate',
  components: {
    BCard,
    BSpinner,
    Form,
  },
  data() {
    return {
      formData: { ...emptyFormData },
      usersData: null,
      loading: false,
    }
  },
  mounted() {},
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    doFormSave() {
      axiosIns({
        method: 'POST',
        url: '/users',
        data: this.formData,
      }).then(response => {
        console.log(response.data)
        if (response.data.status === 'success') {
          this.$router.push({ name: 'users' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Пользователь создан',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Пользователь успешно создан!',
            },
          })
        }
      })
    },
  },
}
</script>
